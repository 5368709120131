import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import Helmet from '../../config/helmet';

import GoogleMaps from '../components/GoogleMaps';
import Container from '../components/UI/Container';

const { Title, Text } = Typography;

const Wrapper = styled(Container)`
  padding-top: 100px !important;
  padding-bottom: 2rem;
`;

const Content = styled.div`
  p {
    text-align: justify;
    margin-bottom: 1.5rem !important;
  }

  ul {
    margin: 1.5rem !important;

    li {
      list-style: disc;
    }
  }

  @media (min-width: 992.98px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1.5rem;
  }
`;

export default () => (
  <Wrapper>
    <Helmet title='About Us' />

    <Title>About</Title>
    <Content>
      <div>
        <Title level={3} style={{ textAlign: 'center' }}>
          Established in 2017, Jorisan Waterfront Resort is a small but relaxing and private place..
        </Title>
        <Text>
          It is seated in just a 2500-square meter area along the serene and calm coastal line of the once famous
          Bauang, La Union. Jorisan Waterfront Resort is conceived by the concerted efforts of a simple yet coherent and
          loving family. In fact, Jorisan is a consolidation of the syllables of the members’ names. The resort takes
          pride in the promotion of the strengthening of family ties, of unity amidst diversity during the conception
          stage. Jorisan Waterfront Resort has a modern Mediterranean concept. It has a boutique style therefore,
          maintaining exclusivity and reclusiveness. It houses 22 rooms, open pavilion, function room, restobar,
          swimming pool, jacuzzi, and sheds that overlook the tranquil West Philippine Sea. Jorisan Waterfront Resort is
          staffed with simple yet warm worthwhile stay away from home.
        </Text>

        <Title level={3} style={{ textAlign: 'center' }}>
          Room Amenities
        </Title>
        <Text>
          Rooms are designed and equipped to provide utmost comfort to make your stay worthwhile. All rooms are covered
          by a 50Mbps fiber optic internet connection and secured through keycard lock system. Other room amenities
          include:
        </Text>

        <ul>
          <li>
            <Text>LED HD TV with Satellite cable channels</Text>
          </li>
          <li>
            <Text>Individually controlled air conditioning</Text>
          </li>
          <li>
            <Text>PABX telephone system</Text>
          </li>
          <li>
            <Text>Smoke detector</Text>
          </li>
          <li>
            <Text>Fire alarm system</Text>
          </li>
          <li>
            <Text>Mirror</Text>
          </li>
          <li>
            <Text>Built-in cabinet</Text>
          </li>
          <li>
            <Text>Work desk and two chairs</Text>
          </li>
          <li>
            <Text>Complimentary coffee and tea and equipments</Text>
          </li>
          <li>
            <Text>In-room digital safe</Text>
          </li>
          <li>
            <Text>Bathroom shower with water heater</Text>
          </li>
          <li>
            <Text>Bathroom toiletries</Text>
          </li>
        </ul>
      </div>
      <GoogleMaps isMarkerShown />
    </Content>
  </Wrapper>
);
